import { template as template_6f01b27da83d4a4b9c218e5374c87e64 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6f01b27da83d4a4b9c218e5374c87e64(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
