import { template as template_0f53c2c1162b40e38cc7be8059931afd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_0f53c2c1162b40e38cc7be8059931afd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
