import { template as template_2c020d5d2a0a4cac85b0b06638d8473d } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_2c020d5d2a0a4cac85b0b06638d8473d(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_2c020d5d2a0a4cac85b0b06638d8473d(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_2c020d5d2a0a4cac85b0b06638d8473d(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
