import { template as template_c7ad3eb4afc64bdf8c024035d095b81b } from "@ember/template-compiler";
const FKText = template_c7ad3eb4afc64bdf8c024035d095b81b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
