import { template as template_9f63058e5bac4de0bde74c3fa92158ac } from "@ember/template-compiler";
const FKControlMenuContainer = template_9f63058e5bac4de0bde74c3fa92158ac(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
